<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-12-22 11:49:49
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-06 20:46:31
 * @FilePath: \yda_web_manage\src\views\businessManage\operationLog\logDetail\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="['operation-log-detail', !isGlobalView && 'inline-margin']">
    <!-- 头部信息 -->
    <LogHeader
      :contentList="contentList"
      :showTitle="isGlobalView"
      :contentBoxStyle="{ padding: '16px 24px 8px 24px' }"
    />
    <div v-show="loading" :class="['content-box', isGlobalView && 'view-height']">
      <component :is="viewComponentList[componentId]" :params="detailInfo"></component>
    </div>
  </div>
</template>
<script>
import CustomEcho from '../components/customEcho/index.vue'
import ProcessEcho from '../components/process/index.vue'
import { reactive, toRefs, onMounted, shallowReactive } from 'vue'
import useOperationLog from '../hooks/useOperationLog'
import LogHeader from '../components/header/index.vue'
import { useRoute } from 'vue-router'
import OperationFlow from '../components/operationFlow'
import { getOperationLogDetail } from '@/apis/businessManage'

export default {
  components: {
    CustomEcho,
    LogHeader,
    ProcessEcho,
    OperationFlow
  },
  setup() {
    const { handleDetailData, globaViewlId } = useOperationLog()
    const state = reactive({
      detailInfo: {
        style: {},
        type: 2,
        showConfig: false,
        data: {}
      },
      loading: false,
      contentList: [],
      isGlobalView: false,
      componentId: 0
    })

    const componentList = shallowReactive({
      viewComponentList: {
        1: OperationFlow, //其他类型
        43: CustomEcho, //流程名称自定义表单
        44: ProcessEcho //流程名称设计
      }
    })

    const route = useRoute()

    const pageInit = async () => {
      const { data, success } = await getOperationLogDetail({ id: route.query.id })
      if (!success) return
      const type = +route.query.type
      const { contentList, details } = handleDetailData(data, type)
      state.contentList = contentList
      // console.log('详情的数据',data,componentList);
      state.detailInfo.data = details
      state.componentId = globaViewlId.includes(type) ? type : 1
      console.log('componentId的值', state.componentId)
      state.isGlobalView = globaViewlId.includes(type)
      state.loading = true
    }

    onMounted(() => {
      pageInit()
    })

    return {
      ...toRefs(state),
      ...toRefs(componentList)
    }
  }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
